import React from 'react';
import { Container, Typography } from '@mui/material';

const ShippingPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Shipping Policy
      </Typography>
      <Typography variant="body2" paragraph>
        Last updated on Nov 9, 2024
      </Typography>
      <Typography variant="body1" paragraph>
        DSVCoding is committed to delivering high-quality recorded lectures and workshop materials in a timely manner. Our shipping policy outlines the delivery timeframes for different types of products.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Recorded Lectures
      </Typography>
      <Typography variant="body1" paragraph>
        Upon successful purchase, you will receive access to recorded lectures within 24 hours. Access details will be sent to the email address you provided during registration.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Workshop Updates
      </Typography>
      <Typography variant="body1" paragraph>
        For live workshops, updates and access links will be shared within 5 days of registration. Ensure your contact information is accurate to receive timely notifications.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or encounter issues with accessing the content, please contact our support team at support@dsvcoding.com.
      </Typography>
      <Typography variant="body1" paragraph>
        DSVCoding is dedicated to providing a seamless experience for all customers. Thank you for choosing us for your learning journey.Dasyapu Sai Vashist
      </Typography>
    </Container>
  );
};

export default ShippingPolicy;
