import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../firebase'; // Import the initialized Firestore instance
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { useDispatch } from 'react-redux';


//send email

export const sendEmailConfirmation = createAsyncThunk(
  'registration/sendEmail',
  async (emailData, { rejectWithValue }) => {
    console.log(emailData , "email data")
    try {
      // Call your backend API or external service to send an email
      const response = await fetch('https://us-central1-projectd-b48c9.cloudfunctions.net/api/payments/send-mail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error('Failed to send confirmation email');
      }

      return { success: true };
    } catch (error) {
      console.log(error , "email error")
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to check for duplicate registration
export const checkDuplicateRegistration = createAsyncThunk(
  'registration/checkDuplicate',
  async (registrationData, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, 'registrations'),
        where('email', '==', registrationData.email),
        where('workshopId', '==', registrationData.workshopId)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we find a matching registration, reject with a message
        return rejectWithValue('You are already registered for this workshop.');
      }

      // If no duplicate is found, return success
      return { duplicateFound: false };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to handle registration submission
export const submitRegistration = createAsyncThunk(
  'registration/submit',
  async (registrationData, { rejectWithValue }) => {
    try {
      const dataWithTimestamp = {
        ...registrationData,
        timestamp: serverTimestamp(),
      };
      const docRef = await addDoc(collection(db, 'registrations'), dataWithTimestamp);
      return { id: docRef.id, ...registrationData, timestamp: new Date() }; // Adjust as needed
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    loading: false,
    success: false,
    error: null,
    duplicateCheckLoading: false,
    duplicateError: null,
    emailSending: false,
    emailSent: false,
    emailError: null,
  },
  reducers: {
    clearRegistrationState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.duplicateCheckLoading = false;
      state.duplicateError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle duplicate check actions
      .addCase(checkDuplicateRegistration.pending, (state) => {
        state.duplicateCheckLoading = true;
        state.duplicateError = null;
        state.loading = true;
      })
      .addCase(checkDuplicateRegistration.fulfilled, (state) => {
        state.duplicateCheckLoading = false;
        state.duplicateError = null;
        state.loading = false;
      })
      .addCase(checkDuplicateRegistration.rejected, (state, action) => {
        state.duplicateCheckLoading = false;
        state.duplicateError = action.payload;
        state.loading = false;
      })

      // Handle registration submission actions
      .addCase(submitRegistration.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitRegistration.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(sendEmailConfirmation.pending, (state) => {
        state.emailSending = true;
        state.emailSent = false;
        state.emailError = null;
      })
      .addCase(sendEmailConfirmation.fulfilled, (state) => {
        state.emailSending = false;
        state.emailSent = true;
        state.emailError = null;
      })
      .addCase(sendEmailConfirmation.rejected, (state, action) => {
        state.emailSending = false;
        state.emailSent = false;
        state.emailError = action.payload;
      });
  },
});

export const { clearRegistrationState } = registrationSlice.actions;

export default registrationSlice.reducer;

