import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography, Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StyledTextField from '../../util/StyledTextField';
import { submitRegistration, clearRegistrationState , checkDuplicateRegistration } from './registrationSlice';
import { createOrder } from '../payment/PaymentSlice';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ selectedWorkshop, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, error,duplicateError } = useSelector((state) => state.registration);
  const orderLoading = useSelector((state) => state.pay.loading)

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  useEffect(() => {
    if (success) {
      setSuccessDialogOpen(true);
    }
    return () => {
      dispatch(clearRegistrationState());
    };
  }, [dispatch, success]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    role: Yup.string().required('Please select your role'),
  });
  const handleSubmit = async (values) => {
    const registrationData = {
      fullName: values.fullName,
      email: values.email,
      role: values.role,
      workshopTitle: selectedWorkshop.title,
      workshopDate: selectedWorkshop.date,
      workshopId: selectedWorkshop.id,
      paymentAmount  :selectedWorkshop.discountedPrice
    };
  
    try {
      // Check for duplicate registration
      const duplicateCheckResult = await dispatch(checkDuplicateRegistration(registrationData));
  
      if (checkDuplicateRegistration.rejected.match(duplicateCheckResult)) {
        console.error('Duplicate registration:', duplicateCheckResult.payload);
        return;
      }
  
      // If no duplicate found, proceed with registration
      // const resultAction = await dispatch(submitRegistration(registrationData));
  
      if (checkDuplicateRegistration.fulfilled.match(duplicateCheckResult)) {
        // Proceed to create the order
        const orderResultAction = await dispatch(createOrder({
          amount: selectedWorkshop.discountedPrice,
          currency: "INR",
          receipt: `receipt_${selectedWorkshop.id}`,
          isWorkshop: true,
        }));
  
        if (createOrder.fulfilled.match(orderResultAction)) {
          // Navigate to the WorkshopPayment page
          navigate('/workshop-payment', { state: { registrationData, workshop: selectedWorkshop } });
        }
      }
    } catch (error) {
    }
  };
  
  const handleDialogClose = () => {
    setSuccessDialogOpen(false);
    handleClose();
  };

  return (
    <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
      <Formik
        initialValues={{ fullName: '', email: '', role: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, isValid, dirty }) => (
          <Form>
            <StyledTextField
              name="fullName"
              label="Full Name"
              error={errors}
              touched={touched}
            />
            <StyledTextField
              name="email"
              label="Email Address"
              error={errors}
              touched={touched}
            />

            <FormControl fullWidth margin="normal" error={touched.role && Boolean(errors.role)}>
              <InputLabel>I am a...</InputLabel>
              <Field as={Select} name="role" label="I am a..." fullWidth>
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="softwareEngineer">Software Engineer</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Field>
              {touched.role && errors.role && (
                <Typography variant="body2" color="error">
                  {errors.role}
                </Typography>
              )}
            </FormControl>

            {duplicateError && (
              <Typography variant="body2" color="error" sx={{ fontFamily: 'Inter, Arial, sans-serif' }}>
                {duplicateError}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || loading || !isValid || !dirty}
              sx={{ mt: 3, mb: 2, backgroundColor: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}
            >
              {(loading || orderLoading) ? <CircularProgress size={24} color="inherit" /> : 'Register'}
            </Button>
          </Form>
        )}
      </Formik>
  
    </DialogContent>
  );
};

export default RegistrationForm;

