import React from 'react';
import { Container, Typography } from '@mui/material';

const ContactUs = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Contact Us Dasyapu Sai Vashist
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or need assistance, feel free to reach out to us through any of the following methods:
      </Typography>
      <Typography variant="h6" gutterBottom>
        Phone
      </Typography>
      <Typography variant="body1" paragraph>
        📞 7382542759
      </Typography>
      <Typography variant="h6" gutterBottom>
        Email
      </Typography>
      <Typography variant="body1" paragraph>
       support@dsvcoding.com
      </Typography>
      <Typography variant="h6" gutterBottom>
        Address
      </Typography>
      <Typography variant="body1" paragraph>
        🏢 357-3/28 Sri Venkateshwara Colony,
        <br />
        Gopalpur, Telangana 506009
      </Typography>
      <Typography variant="body1" paragraph>
        Our support team is available Monday through Friday from 9:00 AM to 6:00 PM IST. We strive to respond to all inquiries within 24 hours.
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for choosing DSVCoding. We look forward to assisting you!
      </Typography>
    </Container>
  );
};

export default ContactUs;
