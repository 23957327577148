import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, CardActions, Button, Container, Box, CardMedia, Alert,CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from './courseslice/allCoursesSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const CourseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { courses, status, error } = useSelector((state) => state.courses);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCourses());
    }

  }, [status, dispatch]);

  const handleViewCourse = (courseId) => {
    navigate(`/courses/${courseId}` , {state:{from : location}});
  };

  if (status === 'loading') {
    return ( <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
    <CircularProgress />
  </Grid>)
  }

  if (status === 'failed') {
    return (
      <Container sx={{ py: 8 }}>
        <Alert severity="error">Recorded Courses Are Coming Soon</Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Grid container spacing={4}>
        {courses.map((course) => (
          <Grid item key={course.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column',boxShadow: 5 }}>
              {course.thumbnail && (
                <CardMedia
                  component="img"
                  height="140"
                  image={course.thumbnail}
                  alt={course.title}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {course.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {course.description}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" color="text.primary">
                    ₹{course.discountedPrice}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                    ₹{course.price}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleViewCourse(course.id)}>
                  View Course
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CourseList;
