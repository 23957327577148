import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Card, CardContent, Typography, CardActions, Button, Container, Box, CardMedia, Dialog, DialogTitle, DialogActions } from '@mui/material';
import RegistrationForm from '../../pages/registration/RegistrationForm'; // Import the RegistrationForm
import EventIcon from '@mui/icons-material/Event'; // For date icon
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // For price icon
import ecom_work from '../../stock/ecom_workshop.png'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
// Dummy data for workshops
const workshops = [
  {
    id: 'react_basics',
    title: 'E-Commerce Website Building Bootcamp',
    description: 'Join our hands-on, interactive workshop to build your own e-commerce website from scratch.',
    date: '16-11-2024 to 17-11-2024',
    time: '06:00 PM - 10:00 PM  IST',
    price: 499,
    discountedPrice: 299,
    image:ecom_work, // Placeholder thumbnail
  }
];

const Workshop = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  const handleOpen = (workshop) => {
    setSelectedWorkshop(workshop);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedWorkshop(null);
  };

  return (
    <Container sx={{ py: 2 }} maxWidth="md">
      <Typography variant="h5" sx={{ color: '#fff', marginBottom: '1rem' }}>
        Upcoming Workshops / Bootcamps
      </Typography>
      <Grid container spacing={4}>
        {workshops.map((workshop) => (
          <Grid item key={workshop.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 5 }}>
              <CardMedia
                component="img"
                height="140"
                image={workshop.image}
                alt={`${workshop.title} thumbnail`}
              />
              {/* <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {workshop.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {workshop.description}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                  <EventIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant="body2">
                    {workshop.date}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocalOfferIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
                  {workshop.discountedPrice ? (
                    <>
                      <Typography variant="body1" color="text.secondary" sx={{ textDecoration: 'line-through', mr: 1 }}>
                        ₹{workshop.price}
                      </Typography>
                      <Typography variant="body1" color="primary">
                        ₹{workshop.discountedPrice}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body1" color="text.primary">
                      ₹{workshop.price}
                    </Typography>
                  )}
                </Box>
              </CardContent> */}
              <CardContent sx={{ flexGrow: 1 }}>
  <Typography gutterBottom variant="h6" component="div">
    {workshop.title}
  </Typography>
  {/* <Typography variant="body1" color="text.secondary" paragraph sx={{ fontSize: '1.1rem', lineHeight: '1.6', marginBottom: '1rem' }}>
    Join our hands-on, interactive Bootcamp to build your own e-commerce website from scratch. Whether you’re a beginner or an experienced developer, you’ll learn essential skills, including React basics, API integration, and creating dynamic shopping experiences.
  </Typography> */}
  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
    <EventIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
    <Typography variant="body2">
      {workshop.date}
    </Typography>
  </Box>
  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
    <AccessTimeFilledIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
    <Typography variant="body2">
      {workshop.time}
    </Typography>
  </Box>
  <Box display="flex" alignItems="center">
    <LocalOfferIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
    {workshop.discountedPrice ? (
      <>
        <Typography variant="body1" color="text.secondary" sx={{ textDecoration: 'line-through', mr: 1 }}>
          ₹{workshop.price}
        </Typography>
        <Typography variant="body1" color="primary">
          ₹{workshop.discountedPrice}
        </Typography>
      </>
    ) : (
      <Typography variant="body1" color="text.primary">
        ₹{workshop.price}
      </Typography>
    )}
  </Box>
</CardContent>

              <CardActions>
                <Button size="small" variant="contained" color="primary" onClick={() => handleOpen(workshop)}>
                  Secure Your Spot Now!
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Registration */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: theme.palette.background.default }}>
          Register for {selectedWorkshop?.title}
        </DialogTitle>
        <RegistrationForm selectedWorkshop={selectedWorkshop} handleClose={handleClose} />
        <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Workshop;
