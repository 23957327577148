import React from 'react';
import HeroSection from '../../components/herosection/heroSection';
import CourseList from '../../components/course/courseList';

const Homepage = () => {
  return (
    <div>
      <HeroSection/>
      <CourseList/>
    </div>
  );
};

export default Homepage;
