import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from '../../firebase'; // Import Firestore instance

// Async thunk to create an order
export const createOrder = createAsyncThunk(
  'payment/create-order',
  async ({ amount, currency, receipt,isWorkshop }, { rejectWithValue }) => {
    console.log(isWorkshop , "is Workahop slice")
    try {
      const response = await axiosInstance.post('https://us-central1-projectd-b48c9.cloudfunctions.net/api/payments/create-order', {
        amount,
        currency,
        receipt,
        isWorkshop
        
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to validate an order
export const validateOrder = createAsyncThunk(
  'payment/validate-order',
  async ({ order_id, payment_id, signature, userId, courseId,isWorkshop }, { rejectWithValue }) => {
    try {
      // Call to validate the order
      const response = await axiosInstance.post('https://us-central1-projectd-b48c9.cloudfunctions.net/api/payments/validate-order', {
        order_id,
        payment_id,
        signature,
        isWorkshop
      });

      // If validation is successful, update Firestore with the purchased course reference
      console.log(userId , "from payment slice")
      
      if(!isWorkshop && isWorkshop !== true){
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
          purchasedCourses: arrayUnion(doc(db, "courses", courseId)) // Add the course reference to purchasedCourses
        });
      }
    

      return response.data;
    } catch (error) {
      console.log(error.response.data,"from paymentslice")
      return rejectWithValue(error.response.data);
    }
  }
);

const PaymentSlice = createSlice({
  name: 'payment',
  initialState: {
    orderId: null,
    loading: false,
    validationLoading: false,
    error: null,
    validationError: null,
    isValid: null,
    isDialogOpen:false
  },
  reducers: {
    resetOrder: (state) => {
      console.log("reset called")
      state.orderId = null;
      state.isValid = null;
      state.error = null;
      state.validationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderId = action.payload.id; 
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validateOrder.pending, (state) => {
        console.log("in pending case")
        state.validationLoading = true;
        state.validationError = null;
      })
      .addCase(validateOrder.fulfilled, (state, action) => {
        console.log(action , "in full filled case")
        state.validationLoading = false;
        state.isDialogOpen = true
        state.isValid = true; // Mark payment as valid
      })
      .addCase(validateOrder.rejected, (state, action) => {
        console.log(action , "in failed case")
        state.validationLoading = false;
        state.validationError = "unable to verify the payment , If any amount deducted it will be credited to you within 5 days";
        state.isValid = false;
      });
  },
});

// Export the resetOrder action
export const { resetOrder } = PaymentSlice.actions;
export default PaymentSlice.reducer;
